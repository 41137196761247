<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Prestamo - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° Prestamo:">
                    <b-form-input type="text" class="text-center" disabled v-model="loan.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" class="text-right" step="any" v-model="loan.amount"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° de Cuotas :">
                    <b-form-input type="number" v-model="loan.dues"></b-form-input>
                    <small v-if="errors.dues" class="form-text text-danger" >Seleccione una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input @change="DayOfGrace" type="date" v-model="loan.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input @change="DayOfGrace" type="date" :min="loan.disbursement_date" v-model="loan.payment_date"></b-form-input>
                    <small v-if="errors.payment_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-select disabled v-model="loan.id_financial_data" :options="financial_data"></b-form-select>
                    <small v-if="errors.id_financial_data" class="form-text text-danger">Seleccione una tasa</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Cambiar Anexos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" @click="CalculateLoan" variant="primary" class="form-control">Calcular Prestamo</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="10">
                  <b-form-group label="Observaciones:">
                    <b-form-textarea v-model="loan.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Anexo:">
                    <b-button type="button" :disabled="loan.file.length == 0" @click="ViewFile" variant="warning" class="form-control">Ver Anexo</b-button>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                <hr>
                </b-col>
                <b-col md="12" class="mb-2">
                  <strong>Garantes</strong> 
                </b-col>
                
                <b-col md="8">
                  <b-form-group label="Garante 1">
                    <v-select placeholder="Seleccione un garante" class="w-100" :filterable="false" label="full_name" v-model="guarantor1" @search="SearchGuarantor1" :options="guarantors1"></v-select>
                    <small v-if="errors.guarantor1" class="form-text text-danger" >Seleccione un garante</small>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Garante de:">
                    <b-form-input disabled :value="guarantor1 == null ? '':guarantor1.message"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" :variant="guarantor1 == null ? 'danger':guarantor1.traffic_lights"></b-button>
                  </b-form-group>
                </b-col>



                <b-col md="8">
                  <b-form-group label="Garante 2">
                    <v-select placeholder="Seleccione un garante" class="w-100" :filterable="false" label="full_name" v-model="guarantor2" @search="SearchGuarantor2" :options="guarantors2"></v-select>
                    <small v-if="errors.guarantor2" class="form-text text-danger" >Seleccione un garante</small>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Garante de:">
                    <b-form-input disabled :value="guarantor2 == null ? '':guarantor2.message"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" :variant="guarantor2 == null ? 'danger':guarantor2.traffic_lights"></b-button>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                <hr>
                </b-col>
                <b-col md="12" class="mb-2">
                  <strong>Desembolso</strong> 
                </b-col>

                <b-col md="12">
                  <b-form-group label="Desembolso:">
                    <v-select placeholder="Seleccione un desembolso" class="w-100" :filterable="false" label="full_name" v-model="check" @search="SearchChecks" :options="checks"></v-select>
                    <small v-if="errors.id_check" class="form-text text-danger" >Seleccione un desembolso</small>
                  </b-form-group>
                </b-col>
            
                
                <b-col md="12">
                <hr>
                </b-col>

                <b-col class="" md="12">
                  <strong>Detalle de Prestamo</strong> 
                </b-col>
                <b-col md="12">
                    <div class="table-responsive mt-3">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td class="pl-1">Total Capital:</td>
                            <td class="text-right"><strong>{{loan.amount}}</strong></td>
                            <td class="pl-1">N° de Cuotas:</td>
                            <td class="text-right"><strong>{{loan.dues}}</strong></td>
                            <td class="pl-1">Total Interés:</td>
                            <td class="text-right"><strong>{{loan.interest_total}}</strong></td>
                            <td class="pl-1">Monto por Cuota:</td>
                            <td class="text-right"><strong>{{loan.final_installment}}</strong></td>
                            <td class="pl-1">Total a Pagar:</td>
                            <td class="text-right"><strong>{{loan.total}}</strong></td>
                            <td class="pl-1">Saldo Capital:</td>
                            <td class="text-right"><strong>{{loan.principal_balance}}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th colspan="12" class="text-center">CRONOGRAMA DE PAGOS</th>
                          </tr>
                          <tr>
                            <th width="3%" class="text-center">#</th>
                            <th width="8%" class="text-center">Fecha</th>
                            <th width="8%" class="text-center">Amort.</th>
                            <th width="8%" class="text-center">Interés</th>
                            <th width="8%" class="text-center">Int. Difer.</th>
                            <th width="8%" class="text-center">Seg. Desg.</th>
                            <th width="8%" class="text-center">Seg. Mult.</th>
                            <th width="8%" class="text-center">ITF</th>
                            <th width="10%" class="text-center">Saldo Cap.</th>
                            <th width="10%" class="text-center">Cuota Final</th>
                            <th width="8%" class="text-center">Dias</th>
                            <th width="8%" class="text-center">Dias Acc.</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, it) in loan.loan_detail" :key="it">
                          <tr>
                            <td class="text-center">{{item.installment_number}}</td>
                            <td class="text-center">{{item.expiration_date}}</td>
                            <td class="text-right">{{item.amortization}}</td>
                            <td class="text-right">{{item.interest}}</td>
                            <td class="text-right">{{item.deferred_interest}}</td>
                            <td class="text-right">{{item.insurance_lien}}</td>
                            <td class="text-right">{{item.multi_insurance}}</td>
                            <td class="text-right">{{item.itf}}</td>
                            <td class="text-right">{{item.principal_balance}}</td>
                            
                            <td class="text-right">{{item.fixed_fee}}</td>
                            <td class="text-center">{{item.days}}</td>
                            <td class="text-center">{{item.accumulated_days}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </b-col>

     
               

                <b-col md="3"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="ValidateAccept" class="form-control" variant="success" ><i class="fas fa-save"></i> Aprobar (F4)</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="button" @click="ValidateCancel" class="form-control" variant="danger" ><i class="fas fa-save"></i> Rechazar (F4)</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>



          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_loan"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Loan',
      role: 3,
      loan: {
          id_loan: '',
          id_partner: '',
          id_user_create: '',
          id_user_validate: '',
          id_financial_data: '',
          number: '',
          amount: '0.00',
          dues: '',
          disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
          days_of_grace: 0,
          payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
          interest_total: '0.00',
          deferred_interest: '0.00',
          total: '0.00',
          final_installment: '0.00',
          principal_balance: '0.00',
          delinquent_day: '0.00',
          file: '',
          file_change: '',
          observation: '',
          guarantor1: '',
          guarantor2: '',
          id_check: '',
          state: '1',
          loan_detail:[],
      },

      clients: [],
      client:null,
      
      checks: [],
      check:null,

      guarantors1: [],
      guarantor1:null,
      guarantors2: [],
      guarantor2:null,

      file:null,
      financial_data:[],
      dues:[
        {value:'',text:'Seleccion una cuota'},
        {value:'6',text:'6 Meses'},
        {value:'12',text:'12 Meses'},
        {value:'18',text:'18 Meses'},
        {value:'24',text:'24 Meses'},
        {value:'30',text:'30 Meses'},
        {value:'36',text:'36 Meses'},
        {value:'42',text:'42 Meses'},
        {value:'48',text:'48 Meses'},
        {value:'54',text:'54 Meses'},
        {value:'60',text:'60 Meses'},
        {value:'66',text:'66 Meses'},
        {value:'72',text:'72 Meses'},
        {value:'78',text:'78 Meses'},
        {value:'84',text:'85 Meses'},
        {value:'90',text:'90 Meses'},

      ],

      //errors
      errors: {
        id_partner: false,
        id_financial_data: false,
        number: false,
        amount: false,
        dues: false,
        disbursement_date: false,
        payment_date: false,
        interest_total: false,
        deferred_interest: false,
        total: false,
        final_installment: false,
        principal_balance: false,
        file: false,
        guarantor1: false,
        guarantor2: false,
        id_check: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewLoan();
    this.ListFinancialData();
  },
  methods: {
    ListFinancialData,
    ViewLoan,
    SearchClients,
    SearchGuarantor1,
    SearchGuarantor2,
    modalClients,
    CalculateLoan,
    DayOfGrace,
    ViewFile,

    EditLoan,
    Validate,
    onFileChange,
    SearchChecks,


    ValidateAccept,
    Accept,
    ValidateCancel,
    Cancel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewFile() {
   let me = this;
  let url = me.url_base + this.loan.file;
  window.open(url,'_blank');
}

function SearchChecks(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-checks/" + this.loan.id_partner+"/4";
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.checks = response.data;
            loading(false);
      })
    }
}

function DayOfGrace() {
  var date1 = moment(this.loan.disbursement_date);
  var date2 = moment(this.loan.payment_date);
  this.loan.days_of_grace = date2.diff(date1, 'days');

  if (parseFloat(this.loan.days_of_grace) < 0) {
    this.loan.payment_date = this.loan.disbursement_date;
    this.DayOfGrace();
  }
}

function ListFinancialData() {
  let me = this;
  let url = me.url_base + "financial-data/list-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.financial_data = [];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.financial_data.push({
          value:element.id_financial_data, text:element.tea
        });

        if (element.default == 1) {
          me.loan.id_financial_data = element.id_financial_data;
        }
      }
    }else{
      me.financial_data = [];
      me.loan.id_financial_data = '';
    }
  })
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function SearchGuarantor1(search, loading) {
  let me = this;
  let url = this.url_base + "loans/guarantor/" + search;
  if (search !== "") {
    loading(true);
    axios({
      method: "GET",
      url: url,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
    }).then(function (response) {
          me.guarantors1 = response.data;
          loading(false);
    })
  }
}

function SearchGuarantor2(search, loading) {
  let me = this;
  let url = this.url_base + "loans/guarantor/" + search;
  if (search !== "") {
    loading(true);
    axios({
      method: "GET",
      url: url,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
    }).then(function (response) {
          me.guarantors2 = response.data;
          loading(false);
    })
  }
}




function ViewLoan() {
  let me = this;
  let id_loan = je.decrypt(this.id_loan);
  let url = me.url_base + "loans/view/"+id_loan;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
          me.loan.id_loan = response.data.result.loan.id_loan; 
          me.loan.id_partner = response.data.result.loan.id_partner; 
          me.loan.id_user_create = response.data.result.loan.id_user_create; 
          me.loan.id_user_validate = response.data.result.loan.id_user_validate; 
          me.loan.id_financial_data = response.data.result.loan.id_financial_data; 
          me.loan.number = response.data.result.loan.number; 
          me.loan.amount = response.data.result.loan.amount; 
          me.loan.dues = response.data.result.loan.dues; 
          me.loan.disbursement_date = response.data.result.loan.disbursement_date; 
          me.loan.days_of_grace = response.data.result.loan.days_of_grace; 
          me.loan.payment_date = response.data.result.loan.payment_date; 
          me.loan.interest_total = response.data.result.loan.interest_total; 
          me.loan.deferred_interest = response.data.result.loan.deferred_interest; 
          me.loan.total = response.data.result.loan.total; 
          me.loan.final_installment = response.data.result.loan.final_installment; 
          me.loan.principal_balance = response.data.result.loan.principal_balance; 
          me.loan.file = response.data.result.loan.file; 
          me.loan.observation = response.data.result.loan.observation; 
          me.loan.guarantor1 = response.data.result.loan.guarantor1; 
          me.loan.guarantor2 = response.data.result.loan.guarantor2; 
          me.loan.id_check = response.data.result.loan.id_check; 
          me.loan.state = response.data.result.loan.state; 
          me.loan.loan_detail = response.data.result.loan_detail; 

          me.file = null;
          me.client = {id: response.data.result.loan.id_partner, full_name:response.data.result.loan.name+" - "+response.data.result.loan.document_number};
          me.guarantor1 = response.data.result.loan.guarantor1 == 0 ? null : {id: response.data.result.loan.guarantor1, full_name:response.data.result.loan.guarantor1_name+" - "+response.data.result.loan.guarantor1_document_number,message : response.data.result.loan.guarantor1_message,traffic_lights : response.data.result.loan.guarantor1_traffic_lights};
          me.guarantor2 = response.data.result.loan.guarantor2 == 0 ? null : {id: response.data.result.loan.guarantor2, full_name:response.data.result.loan.guarantor2_name+" - "+response.data.result.loan.guarantor2_document_number,message : response.data.result.loan.guarantor2_message,traffic_lights : response.data.result.loan.guarantor2_traffic_lights};
          me.check = response.data.result.loan.id_check == 0 ? null : {id: response.data.result.loan.id_check, full_name:response.data.result.loan.check_date+" | "+response.data.result.loan.check_payment_method+" | "+response.data.result.loan.check_number+" | "+response.data.result.loan.check_total};

    }else{
      me.loan.number = '';
    }
  })
}

function onFileChange(e) {
  this.loan.file_change = e.target.files[0];
}


function CalculateLoan() {

  this.errors.amount = this.loan.amount.length == 0 || parseFloat(this.loan.amount) == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;


  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  let url = me.url_base + "loans/calculate-loan";
  let data = me.loan;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.loan.loan_detail = response.data.result.loan_detail;
        me.loan.interest_total = response.data.result.loan_data.interest_total;
        me.loan.final_installment = response.data.result.loan_data.final_installment;
        me.loan.total = response.data.result.loan_data.total;
        me.loan.principal_balance = response.data.result.loan_data.principal_balance;
        me.loan.delinquent_day = response.data.result.delinquent_day;

        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function EditLoan() {
  let me = this;
  let data = new FormData();
  data.append("id_loan", this.loan.id_loan);
  data.append("id_partner", this.client.id);
  data.append("id_user_create", this.user.id_user);
  data.append("guarantor1", this.guarantor1 == null ? 0:this.guarantor1.id);
  data.append("guarantor2", this.guarantor2 == null ? 0:this.guarantor2.id);
  data.append("id_user_validate", this.loan.id_user_validate);
  data.append("id_financial_data", this.loan.id_financial_data);
  data.append("number", this.loan.number);
  data.append("amount", this.loan.amount);
  data.append("dues", this.loan.dues);
  data.append("disbursement_date", this.loan.disbursement_date);
  data.append("days_of_grace", this.loan.days_of_grace);
  data.append("payment_date", this.loan.payment_date);
  data.append("interest_total", this.loan.interest_total);
  data.append("deferred_interest", this.loan.deferred_interest);
  data.append("total", this.loan.total);
  data.append("final_installment", this.loan.final_installment);
  data.append("principal_balance", this.loan.principal_balance);
  data.append("delinquent_day", this.loan.delinquent_day);
  data.append("file", this.loan.file_change);
  data.append("observation", this.loan.observation);
  data.append("guarantor1", this.loan.guarantor1);
  data.append("guarantor2", this.loan.guarantor2);
  data.append("id_check", this.loan.id_check);
  data.append("state", this.loan.state);
  data.append("loan_detail", JSON.stringify(this.loan.loan_detail));

  let url = me.url_base + "loans/edit";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.file = null;
        me.loan.file_change = '';
        me.loan.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_partner = this.client == null ? true : false;
  this.errors.id_financial_data = this.loan.id_financial_data.length == 0 ? true : false;
  this.errors.number = this.loan.number.length == 0 ? true : false;
  this.errors.amount = this.loan.amount.length == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;
  this.errors.interest_total = this.loan.interest_total.length == 0 ? true : false;
  this.errors.deferred_interest = this.loan.deferred_interest.length == 0 ? true : false;
  this.errors.total = parseFloat(this.loan.total) == 0 ? true : false;
  this.errors.final_installment = parseFloat(this.loan.final_installment) == 0 ? true : false;
  this.errors.principal_balance = parseFloat(this.loan.principal_balance) == 0 ? true : false;
  // this.errors.file = this.loan.file.length == 0 ? true : false;
  this.errors.guarantor1 = this.guarantor1 == null ? true : false;
  // this.errors.guarantor2 = this.guarantor1 == null ? true : false;
  

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.interest_total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.deferred_interest) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.final_installment) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.principal_balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.file) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.guarantor1) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.guarantor2) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el prestamo ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditLoan();
    }
  });
}


function ValidateAccept() {

  this.errors.id_partner = this.client == null ? true : false;
  this.errors.id_financial_data = this.loan.id_financial_data.length == 0 ? true : false;
  this.errors.number = this.loan.number.length == 0 ? true : false;
  this.errors.amount = this.loan.amount.length == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;
  this.errors.interest_total = this.loan.interest_total.length == 0 ? true : false;
  this.errors.deferred_interest = this.loan.deferred_interest.length == 0 ? true : false;
  this.errors.total = parseFloat(this.loan.total) == 0 ? true : false;
  this.errors.final_installment = parseFloat(this.loan.final_installment) == 0 ? true : false;
  this.errors.principal_balance = parseFloat(this.loan.principal_balance) == 0 ? true : false;
  // this.errors.file = this.loan.file.length == 0 ? true : false;
  this.errors.guarantor1 = this.guarantor1 == null ? true : false;
  // this.errors.guarantor2 = this.guarantor1 == null ? true : false;
  this.errors.id_check = this.check == null ? true : false;
  

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.interest_total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.deferred_interest) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.final_installment) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.principal_balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.file) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.guarantor1) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.guarantor2) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_check) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  Swal.fire({
    title: "Esta seguro de aceptar el prestamo ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.Accept();
    }
  });

}

function Accept() {
  let me = this;
  let data = new FormData();
  data.append("id_loan", this.loan.id_loan);
  data.append("id_partner", this.client.id);
  data.append("id_user_create", this.user.id_user);
  data.append("guarantor1", this.guarantor1 == null ? 0:this.guarantor1.id);
  data.append("guarantor2", this.guarantor2 == null ? 0:this.guarantor2.id);
  data.append("id_user_validate", this.user.id_user);
  data.append("id_financial_data", this.loan.id_financial_data);
  data.append("number", this.loan.number);
  data.append("amount", this.loan.amount);
  data.append("dues", this.loan.dues);
  data.append("disbursement_date", this.loan.disbursement_date);
  data.append("days_of_grace", this.loan.days_of_grace);
  data.append("payment_date", this.loan.payment_date);
  data.append("interest_total", this.loan.interest_total);
  data.append("deferred_interest", this.loan.deferred_interest);
  data.append("total", this.loan.total);
  data.append("final_installment", this.loan.final_installment);
  data.append("principal_balance", this.loan.principal_balance);
  data.append("delinquent_day", this.loan.delinquent_day);
  data.append("file", this.loan.file);
  data.append("observation", this.loan.observation);
  data.append("guarantor1", this.loan.guarantor1);
  data.append("guarantor2", this.loan.guarantor2);
  data.append("id_check", this.check.id);
  data.append("state", this.loan.state);
  data.append("loan_detail", JSON.stringify(this.loan.loan_detail));

  let url = me.url_base + "loans/accept";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.$router.push({
          name: "LoanList",
        });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ValidateCancel() {

  this.errors.id_partner = this.client == null ? true : false;
  this.errors.id_financial_data = this.loan.id_financial_data.length == 0 ? true : false;
  this.errors.number = this.loan.number.length == 0 ? true : false;
  this.errors.amount = this.loan.amount.length == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;
  this.errors.interest_total = this.loan.interest_total.length == 0 ? true : false;
  this.errors.deferred_interest = this.loan.deferred_interest.length == 0 ? true : false;
  this.errors.total = parseFloat(this.loan.total) == 0 ? true : false;
  this.errors.final_installment = parseFloat(this.loan.final_installment) == 0 ? true : false;
  this.errors.principal_balance = parseFloat(this.loan.principal_balance) == 0 ? true : false;
  // this.errors.file = this.loan.file.length == 0 ? true : false;
  this.errors.guarantor1 = this.guarantor1 == null ? true : false;
  // this.errors.guarantor2 = this.guarantor1 == null ? true : false;
  

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.interest_total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.deferred_interest) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.final_installment) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.principal_balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.file) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.guarantor1) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  // if (this.errors.guarantor2) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de cancelar el prestamo ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.Cancel();
    }
  });
}


function Cancel() {
  let me = this;
  let data = new FormData();
  data.append("id_loan", this.loan.id_loan);
  data.append("id_partner", this.client.id);
  data.append("id_user_create", this.user.id_user);
  data.append("guarantor1", this.guarantor1 == null ? 0:this.guarantor1.id);
  data.append("guarantor2", this.guarantor2 == null ? 0:this.guarantor2.id);
    data.append("id_user_validate", this.user.id_user);
  data.append("id_financial_data", this.loan.id_financial_data);
  data.append("number", this.loan.number);
  data.append("amount", this.loan.amount);
  data.append("dues", this.loan.dues);
  data.append("disbursement_date", this.loan.disbursement_date);
  data.append("days_of_grace", this.loan.days_of_grace);
  data.append("payment_date", this.loan.payment_date);
  data.append("interest_total", this.loan.interest_total);
  data.append("deferred_interest", this.loan.deferred_interest);
  data.append("total", this.loan.total);
  data.append("final_installment", this.loan.final_installment);
  data.append("principal_balance", this.loan.principal_balance);
  data.append("delinquent_day", this.loan.delinquent_day);
  data.append("file", this.loan.file);
  data.append("observation", this.loan.observation);
  data.append("guarantor1", this.loan.guarantor1);
  data.append("guarantor2", this.loan.guarantor2);
  data.append("id_check", this.loan.id_check);
  data.append("state", this.loan.state);
  data.append("loan_detail", JSON.stringify(this.loan.loan_detail));

  let url = me.url_base + "loans/cancel";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.$router.push({
          name: "LoanList",
        });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


</script>
